import React, { ReactElement } from 'react'
import { Panel } from 'rsuite'
import { useNavigate } from 'react-router-dom'
import { format, parseISO } from 'date-fns'

import { CreateVoucherParams, Voucher, VoucherFormData } from '../../types/voucher'
import { createVoucher, editVoucher } from '../../services/graphql/queries/voucher'
import { PanelHeader } from '../../components'
import { VoucherForm } from '../../forms/VoucherForm'
import { useApi, useApiGroup, useFormState } from '../../app/hooks'

export default function VoucherEdit ({
  voucher,
  onFinish,
}: {
  voucher?: Voucher,
  onFinish?: () => void
}): ReactElement {
  const navigate = useNavigate()
  const [formData, setField] = useFormState<VoucherFormData>(
    voucher
      ? {
        ...voucher,
        location: voucher.location,
        startDate: new Date(voucher.startDate),
        expiryDate: new Date(voucher.expiryDate),
      }
      : {}
  )

  const api = useApiGroup({
    create: useApi(createVoucher),
    update: useApi(editVoucher),
  })

  const save = () => {
    const extractDate = (d: Date) => format(parseISO(d.toISOString()), 'yyyy-MM-dd')
    const { startDate, expiryDate, ...data } = formData

    const voucherDTO = {
      ...data,
      expiryDate: extractDate(expiryDate),
      ...(startDate && { startDate: extractDate(startDate) }),
    } as CreateVoucherParams
    if (onFinish) {
      api.requests.update.sendRequest({ ...voucherDTO, id: voucher.id }, (received) => {
        onFinish()
        return received
      })
    } else {
      api.requests.create.sendRequest(voucherDTO, (received) => {
        navigate(`/admin/vouchers/${received.id}`)
        return received
      })
    }
  }

  const cancel = () => {
    if (onFinish) onFinish()
    else navigate('/admin/vouchers/')
  }

  const formId = 'vouchers-details-form'
  return (
    <Panel
      header={(
        <PanelHeader
          editing
          formId={formId}
          onCancel={cancel}
          header={`${voucher ? 'Edit' : 'New'} Voucher`}
        />
      )}
    >
      <VoucherForm
        formId={formId}
        formValue={formData}
        onChange={setField}
        onSubmit={save}
        error={voucher ? api.requests.update.getResponse().error : api.requests.create.getResponse().error}
      />
    </Panel>
  )
}
