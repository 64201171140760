import { Panel } from 'rsuite'
import React from 'react'

import { Voucher } from '../../types/voucher'

export default function QRDisplay ({ voucher }: { voucher: Voucher}) {
  return (
    <Panel
      header={<h2>Access Code</h2>}
    >
      {voucher.code
        ? <div style={{ width: 128, height: 128 }} dangerouslySetInnerHTML={{ __html: voucher.code }} />
        : <p>No QR code found</p>}
    </Panel>
  )
}
