import React, { ReactElement, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Loader } from 'rsuite'

import {
  useApi, useApiGroup, usePageTitle
} from '../../app/hooks'
import { NotFound } from '../error-pages/NotFound'
import {
  getVoucher,
  getVoucherVehicleVisits
} from '../../services/graphql/queries/voucher'
import QRDisplay from './QRDisplay'
import { VoucherScanHistory } from './VoucherScanHistory'
import VoucherEdit from './VoucherEdit'
import VoucherDefinition from './VoucherDefinition'

/**
 * Component to view and engage editing mode of a voucher
 */
function VoucherDetails () : ReactElement {
  usePageTitle('View voucher')
  const { id } = useParams()
  const { state }: { state?: { edit?: boolean }} = useLocation()
  const [isEditing, setIsEditing] = useState(state?.edit ? state.edit : false)
  const [isLoading, setIsLoading] = useState(true)

  const api = useApiGroup({
    fetch: useApi(getVoucher),
    fetchVisits: useApi(getVoucherVehicleVisits),
  })

  const fetchResponse = api.requests.fetch.getResponse()
  const fetchVisitsResponse = api.requests.fetchVisits.getResponse()

  useEffect(() => {
    if (id) {
      api.requests.fetch.sendRequest({ id })
      api.requests.fetchVisits.sendRequest({ voucherId: id })
    }
    return api.cleanup
  }, [id])

  useEffect(() => {
    if (!fetchResponse.loading) setIsLoading(false)
  }, [fetchResponse.loading])

  const afterEditing = () => {
    api.requests.fetch.sendRequest({ id }, (values) => {
      setIsEditing(false)
      return values
    })
  }

  if (fetchResponse.error || (fetchResponse.success && !fetchResponse.data)) return <NotFound />

  if (isLoading || fetchResponse.loading) return <Loader center content="Loading..." />

  if (isEditing) {
    return (
      <VoucherEdit
        voucher={fetchResponse.data}
        onFinish={afterEditing}
      />
    )
  }

  return (
    <>
      <VoucherDefinition voucher={fetchResponse.data} onEdit={() => setIsEditing(true)} />
      <QRDisplay voucher={fetchResponse.data} />
      <VoucherScanHistory visits={fetchVisitsResponse.data?.items} loading={fetchVisitsResponse.loading} />
    </>
  )
}

export {
  VoucherDetails
}
