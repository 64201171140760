import React, { ReactElement } from 'react'
import { Panel, Table } from 'rsuite'

import { TableData } from '../../components'
import { VoucherVisit } from '../../types/voucher'

type QrTableProps = {
  visits: VoucherVisit[]
  loading?: boolean
}

function HistoryPanel ({ children }: { children: ReactElement }) {
  return (
    <Panel
      header={<h2>Parking history</h2>}
    >
      {children}
    </Panel>
  )
}

function VoucherScanHistory ({
  visits,
  loading,
}: QrTableProps):React.ReactElement {
  if (!loading && !visits) return <HistoryPanel><p>No history found</p></HistoryPanel>

  return (
    <HistoryPanel>
      <div className="qr-table">
        <Table
          wordWrap="break-word"
          data={visits}
          loading={loading}
          autoHeight
        >
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>Car Park</Table.HeaderCell>
            <TableData<VoucherVisit>
              dataKey="location"
              content={({ location }) => location.name}
            />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>Rego</Table.HeaderCell>
            <TableData dataKey="rego" />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>Entry date time</Table.HeaderCell>
            <TableData dataKey="entrytime" format="datetime" />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>Exit date time</Table.HeaderCell>
            <TableData dataKey="exittime" format="datetime" />
          </Table.Column>
        </Table>
      </div>
    </HistoryPanel>
  )
}

export {
  VoucherScanHistory
}
