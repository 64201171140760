import React from 'react'
import { Panel } from 'rsuite'

import { Voucher } from '../../types/voucher'
import { DefinitionList, PanelHeader } from '../../components'

export default function VoucherDefinition ({ voucher, onEdit }: { voucher: Voucher, onEdit: () => void }) {
  const voucherData = {
    Name: voucher.name,
    Type: voucher.type,
    'Car park': voucher.locationName,
    ...(voucher.startDate && { 'Start date': voucher.startDate }),
    'Expiry date': voucher.expiryDate,
    ...(voucher.phoneNumber && { 'Recipient phone number': voucher.phoneNumber }),
    ...(voucher.email && { 'Recipient email': voucher.email }),
    Flags: voucher.flags?.length > 0 ? voucher.flags : 'No flags set',
  }

  return (
    <Panel
      data-testid="voucher-definition"
      header={(
        <PanelHeader
          header="Voucher Details"
          onEdit={onEdit}
        />
      )}
    >
      <DefinitionList
        data={voucherData}
      />
    </Panel>
  )
}
