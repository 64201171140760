import { createQuery } from '../helpers'
import type { PaginatedResponse, Query } from '../../../types/api'
import {
  CreateVoucherParams, EditVoucherParams, GetVoucherVehicleVisitsParams, Voucher
} from '../../../types/voucher'
import { VehicleVisitDTO } from '../../../types/vehicle-visits'

const voucherProps = `
  id
  type
  name 
  expiryDate
  startDate
  phoneNumber
  email
  location
  locationName
  code
`

const visitProps = `
  items {
    id
    user
    entrytime
    exittime
    location
    rego
    vehicle
    access
    status
  }
  next
  limit
`

const createVoucher: Query<{ id: string }, CreateVoucherParams> = createQuery(`
  mutation createVoucher(
    $location: ID!,
    $name: String!,
    $type: VoucherType!,
    $expiryDate: String!,
    $startDate: String,
    $phoneNumber: String,
    $email: String
  ) {
    createVoucher(
      location: $location
      name: $name
      type: $type
      expiryDate: $expiryDate
      startDate: $startDate
      phoneNumber: $phoneNumber
      email: $email
    ) {
      id
    }
  }
`)

const getVoucher: Query<Voucher, {
  id: string
}> = createQuery(`
  query getVoucher($id: ID!) {
    getVoucher(id: $id) {
      ${voucherProps}
    }
  }
`)

const getVouchers: Query<PaginatedResponse<Voucher>, {
  limit?: number,
  next?: string
}> = createQuery(`
  query getVouchers($limit: Int, $next: String) {
    getVouchers(limit: $limit, next: $next) {
      items {
        ${voucherProps}
      }
      next
      limit
    }
  }
`)

const revokeVoucher: Query<void, {
  id: string
}> = createQuery(`
  mutation revokeVoucher($id: ID!) {
    revokeVoucher(id: $id) {
      id
    }
  }
`)

const editVoucher: Query<{ id: string }, EditVoucherParams> = createQuery(`
  mutation editVoucher(
    $id: ID!, 
    $location: ID!,
    $name: String!,
    $type: VoucherType!,
    $expiryDate: String!,
    $startDate: String,
    $phoneNumber: String,
    $email: String
  ) {
    editVoucher(
      id: $id
      location: $location
      name: $name
      type: $type
      expiryDate: $expiryDate
      startDate: $startDate
      phoneNumber: $phoneNumber
      email: $email
   ) {
    id
   }
  }
`)

const getVoucherVehicleVisits: Query<
PaginatedResponse<VehicleVisitDTO>,
GetVoucherVehicleVisitsParams
> = createQuery(`
  query getVoucherVehicleVisits(
    $voucherId: ID!,
    $fromDate: String,
    $toDate: String,
    $location: String,
    $vehicle: String,
    $next: String,
    $limit: Int
  ) { 
    getVoucherVehicleVisits(
      voucher: $voucherId,
      fromDate: $fromDate,
      toDate: $toDate,
      location: $location,
      vehicle: $vehicle,
      next: $next,
      limit: $limit
    ) {
      ${visitProps}
    }
  }
`)

export {
  createVoucher,
  getVoucher,
  getVouchers,
  revokeVoucher,
  editVoucher,
  getVoucherVehicleVisits
}
