import React, { ReactElement, useEffect, useState } from 'react'
import {
  ButtonToolbar,
  IconButton,
  Panel
} from 'rsuite'
import { Link, useNavigate } from 'react-router-dom'
import { Icon } from '@rsuite/icons'
import { FaPlus } from 'react-icons/fa'

import {
  useApi, useModal,
  usePageTitle
} from '../../app/hooks'
import { VoucherTable } from './VoucherTable'
import { usePaginatedApi } from '../../app/hooks/paginatedApi'
import { Voucher } from '../../types/voucher'
import { getVouchers, revokeVoucher } from '../../services/graphql/queries/voucher'
import { Modal } from '../../components'
import { RevokeVoucherModal } from './RevokeVoucherModal'
import { replaceInPaginatedWithId } from '../../helpers'

function VoucherList ():ReactElement {
  usePageTitle('Vouchers')
  const navigate = useNavigate()
  const [selectedVoucher, setSelectedVoucher] = useState<Voucher | null>(null)
  const revokeModal = useModal()

  const voucherQuery = usePaginatedApi({
    query: getVouchers,
    fetchParams: {},
    itemsPerPage: 10,
    queryOptions: {
      cleanUpOnDismount: true,
    },
  })

  useEffect(() => {
    if (!voucherQuery.loading) {
      voucherQuery.sendRequest({})
    }
  }, [])

  const revokeVoucherQuery = useApi(revokeVoucher)

  const activeVouchers = voucherQuery.currentPage

  const onEdit = (voucher: Voucher) => {
    navigate(`/admin/vouchers/${voucher.id}`, { state: { edit: true } })
  }

  const onRevoke = (voucher: Voucher) => {
    setSelectedVoucher(voucher)
    revokeModal.show()
  }

  const revokeQrConfirm = () => {
    if (selectedVoucher) {
      const { setData } = voucherQuery.apiHook
      revokeVoucherQuery.sendRequest({ id: selectedVoucher.id }, () => {
        setData((old) => replaceInPaginatedWithId(old, selectedVoucher))
        revokeModal.hide()
        return null
      })
    }
  }

  return (
    <div>
      <Panel
        header={(
          <>
            <h2>Vouchers</h2>
            <ButtonToolbar>
              <IconButton
                appearance="subtle"
                icon={<Icon as={FaPlus} />}
                as={Link}
                to="new"
              />
            </ButtonToolbar>
          </>
        )}
      >
        <VoucherTable
          vouchers={activeVouchers || []}
          loading={voucherQuery.loading}
          nextPage={voucherQuery.next || undefined}
          prevPage={voucherQuery.prev || undefined}
          onEdit={onEdit}
          onRevoke={onRevoke}
        />
      </Panel>
      <Modal hook={revokeModal}>
        <RevokeVoucherModal
          onConfirm={revokeQrConfirm}
          onClose={revokeModal.hide}
          loading={revokeVoucherQuery.getResponse().loading}
        />
      </Modal>
    </div>
  )
}

export {
  VoucherList
}
